import {Controller} from "stimulus"
import $ from 'jquery';

let ban_remain_time = (obj) => {
    let timeOut = 1000;

    let interval = setInterval(function () {
        let date_future = new Date(obj.dataset.timeout * 1000);
        let date_now = new Date();

        let seconds = Math.floor((date_future - (date_now)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

        if((hours + minutes + seconds) > 0){
            $(obj).text(`${hours} ${I18n.t('global.time.hours')} - ${minutes} ${I18n.t('global.time.minutes')} - ${seconds} ${I18n.t('global.time.seconds')}`);
        }else{
            $(obj).text(`0 ${I18n.t('global.time.hours')} - 0 ${I18n.t('global.time.minutes')} - 0 ${I18n.t('global.time.seconds')}`);
            clearInterval(interval);
        }

    }, timeOut);
}


export default class extends Controller {

    connect() {
       ban_remain_time(this.element);
    }
}