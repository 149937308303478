import {Controller} from "stimulus"
import $ from 'jquery';

import popper from '@popperjs/core'
import bootbox from "bootbox";
import {validate_email} from "./verificaciones_controller";
import {checkSessionVarWithData, setSessionVar} from "./sessionStorage_controller";
import {create_array_for_product} from "./b2b_controller";


export default class extends Controller {



    connect() {
        let element = $(this.element);
        let type = element[0].dataset.type;
        element.on('change', (e) => {
            if (e.target.checked) {
                let dialog_user_data = bootbox.dialog({
                    title: I18n.t('reservation.parent_book.title'), message: `<p class='text-danger small'>${I18n.t('reservation.parent_book.required_fields')}</p>
                                    <p class='text-danger small'>${I18n.t('reservation.parent_book.helper')}</p>
                                    <div id="parent_book_${type}" class="row">
                                        <div class="col-12 mb-2">
                                         <input id="user_username_${type}" type="text" placeholder="* ${I18n.t('reservation.parent_book.name')}" class="form-control required"> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_lastname_${type}" type="text" placeholder="* ${I18n.t('reservation.parent_book.lastname')}" class="form-control required"> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_passport_${type}" type="text" placeholder="* ${I18n.t('reservation.parent_book.passport')}" class="form-control required"> 
                                        </div>
                                         <div class="col-12 mb-2">
                                         <input id="user_email_${type}"  type="email" placeholder="* ${I18n.t('reservation.parent_book.email')}" class="form-control required">
                                         <small class="text-danger"> </small> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_city_${type}" type="text" placeholder="* ${I18n.t('reservation.parent_book.city')}" class="form-control required"> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_address_${type}" type="text" placeholder="* ${I18n.t('reservation.parent_book.address')}" class="form-control required"> 
                                        </div>
                                    </div>
                              `, size: 'small', buttons: {
                        cancel: {
                            label: I18n.t('reservation.parent_book.buttons.cancel'),
                            className: 'btn-secondary',
                            callback: function (e) {
                                element.prop('checked', false).trigger('change');
                                create_array_for_product('is_bus_owner',true);
                                setSessionVar('cart_owner','','delete').then()
                            }
                        },
                        ok: {
                            label: I18n.t('reservation.parent_book.buttons.ok'),
                            className: 'btn-success',
                            callback: function (e) {
                                let count = 0;
                                let form_required = $(`#parent_book_${type}`).find('.required');
                                let name = $(`#user_username_${type}`).val();
                                let lastname = $(`#user_lastname_${type}`).val();
                                let passport = $(`#user_passport_${type}`).val();
                                let email = $(`#user_email_${type}`).val();
                                let city = $(`#user_city_${type}`).val();
                                let address = $(`#user_address_${type}`).val();

                                form_required.map((index, element) => {
                                    if (element.value.isEmpty()) {
                                        count += 1;
                                        $(element)[0].classList.remove('is-valid');
                                        $(element)[0].classList.add('is-invalid');
                                    } else {
                                        $(element)[0].classList.add('is-valid');
                                        $(element)[0].classList.remove('is-invalid');
                                    }
                                });

                                validate_email(email).then(success => {
                                    if (success) {
                                        if (count > 0) {
                                            return false;
                                        } else {
                                            $('button.bootbox-close-button').trigger('click');
                                            setSessionVar('cart_owner',{
                                                name: name,
                                                lastname: lastname,
                                                passport: passport,
                                                email: email,
                                                city: city,
                                                address: address,
                                            }, 'add', false, false).then(result => {
                                                console.log(result);
                                            })

                                            return true;
                                        }
                                    } else {
                                        let email_user_form = document.getElementById(`user_email_${type}`);
                                        email_user_form.classList.add('is-invalid');
                                        email_user_form.classList.remove('is-valid');
                                    }
                                })

                                return false;
                            }
                        }
                    }
                })
                setTimeout(() => {
                    $('.bootbox.modal')[0].removeAttribute('tabindex');
                }, 500);
            }else{
                create_array_for_product('is_bus_owner',true);
                setSessionVar('cart_owner','','delete').then();
            }
        })
    }
}