import {Controller} from "stimulus"
import $ from 'jquery';

import popper from '@popperjs/core'



export default class extends Controller {

    connect() {
       let textarray = this.element.textContent.split('');
       this.element.textContent = '';
       for(let i = 0; i< textarray.length; i++){
           this.element.innerHTML += `<span style="transform: rotate(${(((i+1)*10)-(135 + textarray.length))}deg);">${textarray[i]}</span>`
       }
    }
}