import {Controller} from "stimulus"
import $ from 'jquery';
import popper from '@popperjs/core'
import {validate} from "./validate_controller";
import toast from "bootstrap/js/src/toast";
import {validate_email} from "./verificaciones_controller";


export default class extends Controller {

    initialize () {
        let url = location.href;
        let url_register = url.includes('register=true');

        if(url_register){
            $('#link_register_modal').trigger('click');
        }
    }

    connect () {
        let btnRegisterUserNextData = $ ('.btn-next-data');
        let btnRegisterUserPrevData = $ ('.btn-prev-data');

        if (btnRegisterUserNextData.length > 0) {
            btnRegisterUserNextData.map ((index, elem) => {
                $ (elem).on ('click', function (e) {
                    $ (`#${e.target.dataset.next}`).carousel ('next');
                })
            })
        }

        if (btnRegisterUserPrevData.length > 0) {
            btnRegisterUserPrevData.map ((index, elem) => {
                $ (elem).on ('click', function (e) {
                    $ (`#${e.target.dataset.next}`).carousel ('prev');
                })
            })
        }
    }

    register () {
        let form = $ ('#register_user_form');
        let authenticity_token = form.find('input[name="authenticity_token"]');
        let username = form.find('input#register_username');
        let lastname = form.find('input#register_lastname');
        let email = form.find('input#register_email');
        let password = form.find('input#register_password');
        let password_confirmation = form.find('input#register_password_confirmation');
        let address = form.find('input#register_address');
        let passport = form.find('input#register_passport');
        let country_id = form.find('input#register_country_id_selected');
        let country_code = form.find('input#register_country_code');
        let country_city = form.find('input#register_country_city');
        let phone = form.find('input#register_phone');
        let create_and_auth = form.find('input#create_and_auth');
        validate ('register_user_form').then (
            validate_form => {
                validate_email(email.val()).then(
                    email_validate => {
                        if(email_validate){
                            if (validate_form.next) {
                                $.ajax ({
                                    url: form[0].action,
                                    type: form[0].method,
                                    data: {
                                        "authenticity_token": authenticity_token.val(),
                                        "register_user": "register",
                                        "user": {
                                            "username": username.val(),
                                            "lastname": lastname.val(),
                                            "email": email.val(),
                                            "password": password.val(),
                                            "password_confirmation": password_confirmation.val(),
                                            "passport": passport.val(),
                                            "address": address.val(),
                                            "country_id": country_id.val(),
                                            "country_code": country_code.val(),
                                            "country_city": country_city.val(),
                                            "phone": phone.val()
                                        },
                                        "create_and_auth": create_and_auth.is(':checked'),
                                        "locale": sessionStorage.getItem('locale'),
                                        "format": 'json'
                                    },
                                    beforeSend: function () {

                                    },
                                    success: function (data) {
                                        if(data.success){
                                            toastr.success(data.message, data.code);
                                            let close_btn_modal = $('.close-modal-login');
                                            if(close_btn_modal.length > 0){
                                                close_btn_modal.map((index, elem) => {
                                                    $(elem).trigger('click');
                                                })

                                            }
                                        }else{
                                            toastr.error(data.message, data.code);
                                        }
                                    }
                                })
                            } else {
                                $ (`#register_step_user`).carousel (0);
                            }
                        }else{
                            toastr.error('Email no válido');
                            email[0].classList.add('is-invalid');
                        }
                    }
                )

            }
        )
    }
}