import {Controller} from "stimulus"
import $ from 'jquery';

import popper from '@popperjs/core'



export default class extends Controller {

    initialize () {
        let cookie_card = $('#cookie_card');
        if(cookie_card.length > 0){
            setTimeout(()=>{
                cookie_card[0].classList.add('active');
            }, 20000)
        }
    }

    connect() {
        let enable_analitycs_button = $('#enable_analitics');
        enable_analitycs_button.on('click',function () {
          $.ajax({
              url: location.origin+'/enable_analytics',
              data: {format: 'json'},
              type: 'POST',
              beforeSend: function () {
                  enable_analitycs_button.html('<span class="fa fa-circle-o-notch fa-spin"></span>');
              },
              success: function (data) {
                  if(data.success){
                      let cookie_card = $('#cookie_card');
                      sessionStorage.setItem('enable_analytics','true');
                      cookie_card[0].classList.remove('active');
                      setTimeout(function () {
                          cookie_card.remove();
                      },1000);
                  }
              }
          })
       })
    }
}